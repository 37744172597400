import toHtmlForProps from '@/utils/toHtmlForProps';
import {getStatusCodesResolver} from '@/utils/jobStatuses';

export const statuses = {
  selected: {
    code: ['NEW', 'INITIATED', 'Reimbursement selected'],
    virtualStatus: 'Reimbursement selected',
    heading: 'Reimbursement selected. Please email us your repair receipt:',
  },
  // TODO: double check all status codes below
  repairReceiptAtReview: {
    code: 'ACCEPTED',
    virtualStatus: 'Reimbursement in progress',
    heading: 'Your receipt has been successfully uploaded and will be reviewed',
    text: 'Your payment will take up to 30 days to be processed and sent to you.',
  },
  processed: {
    code: 'COMPLETED',
    virtualStatus: 'Reimbursement processed',
    text: (_, {phones}) => `
      We have reviewed your reimbursement.
      HomeServe will be contacting you with the amount to be paid out to you.
      If you don't hear from us, feel free to call HomeServe at ${phones.contactCenter}.
    `,
  },
  completed: {
    code: 'CLOSED',
    virtualStatus: 'Reimbursement completed',
    text: 'Your Reimbursement has been successfully completed.',
  },
  cancelled: {
    code: 'CANCELLED',
    virtualStatus: 'Reimbursement cancelled',
    text: (_, {phones}) => `
      You have cancelled this Service request.
      If you have any questions, please call HomeServe at ${phones.contactCenter}.
    `,
  },
  // TODO: there is complicated logic when
  // the request was denined rather than just cancelled
  // See: https://www.figma.com/file/iDdZ0V1xGKzRdcYvx8kxlH/WB_DG_0037-Consumer-Electronics?node-id=3649%3A34807
  // ask to put a job into this particular status
  // and figure out how to tell it apart from basic cancelled
  denied: {
    code: 'DENIED',
    virtualStatus: 'Reimbursement denied',
    text: (_, {phones}) => `
      Your reimbursement has been reviewed and has been denied.
      HomeServe will be contacting you with an explanation.
      If you don't hear from us, feel free to call HomeServe at ${phones.contactCenter}.
      You can also refer to your Service Agreement for more information.
    `,
  },
};

toHtmlForProps(statuses, 'text');

export const statusCodesResolver = getStatusCodesResolver(statuses);

export default statuses;
