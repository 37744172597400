import toHtmlForProps from '@/utils/toHtmlForProps';
import {getSharedStatuses, getStatusCodesResolver} from '@/utils/jobStatuses';

const callHomeServe = (_, {phones}) =>
  `If you have any additional questions, feel free to call HomeServe at ${phones.contactCenter}.`;

const successfullyCompletedMessage = 'Your Home Service repair has successfully been completed.';

const sharedStatuses = getSharedStatuses({
  serviceCompleted: {
    text: successfullyCompletedMessage,
  },
  repairCompleted: {
    text: successfullyCompletedMessage,
  },
  deviceNotEligibleForRepair: {
    text: (_, {phones}) => `
      The technician determined that the device is not eligible for repair.
      Please check your Service Agreement for more information.
      If you have any additional questions, feel free to call HomeServe at ${phones.contactCenter}.
    `,
  },
});

function getDateAndTime({scheduledServiceDate, claimDetails}, {formattedDate, timeFromTo}) {
  let dateAndTimeChunks = [];

  if (scheduledServiceDate) {
    const date = formattedDate(scheduledServiceDate?.split('T')?.[0]);
    dateAndTimeChunks.push(date);
  }

  const {scheduledFromTime, scheduledToTime} = claimDetails;
  if (scheduledFromTime && scheduledToTime) {
    const time = timeFromTo({
      from: scheduledFromTime?.split?.(':'),
      to: scheduledToTime?.split?.(':'),
    });
    dateAndTimeChunks.push(time);
  }

  return dateAndTimeChunks.join(' ');
}

export const homeServiceStatuses = {
  requestAccepted: {
    code: ['NEW', 'SC0200'],
    virtualStatus: 'In-home request accepted', // workaround for not working update of serviceJobSubStatusName
    text: 'Your call has been scheduled at date and time listed below. The technician will attempt to diagnose and troubleshoot the problem during the call. If the problem can be solved over the phone, there will be no need for a subsequent in-home visit.',
    dateAndTimeTitle: 'Expected date and time of the call:',
  },
  visitScheduled: {
    code: 'SC0202',
    text: 'The technician’s in-home visit has been scheduled for the repair of your device.',
    dateAndTimeTitle: 'Date and time of the upcoming visit:',
  },
  customerNotAvailable: {
    code: 'SC0203',
    heading: 'You were not at home',
    text: (jobInfo, getters) => `
      The technician attempted to visit you on ${getDateAndTime(
        jobInfo,
        getters
      )}, but he reported that you were not at home.
      Please call the technician to reschedule.
    `,
    text2: callHomeServe,
  },
  customerNotReachable: {
    code: 'SC0201',
    heading: 'We were unable to reach you',
    text: (jobInfo, getters) => `
      We attempted to call you on ${getDateAndTime(jobInfo, getters)}.
      Please call the technician back so the issue can be addressed and diagnosed.
    `,
    text2: callHomeServe,
  },
};

export const statuses = {
  ...sharedStatuses,
  ...homeServiceStatuses,
};

toHtmlForProps(statuses, 'text', 'text2');

export const statusCodesResolver = getStatusCodesResolver(statuses);

export default statuses;
