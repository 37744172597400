import {statusCodesResolver as carryInStatusCodesResolver} from '@/constants/carry-in/statuses';
import {statusCodesResolver as depotStatusCodesResolver} from '@/constants/depot/statuses';
import {statusCodesResolver as replacementStatusCodesResolver} from '@/constants/replacement/statuses';
import {statusCodesResolver as buyoutStatusCodesResolver} from '@/constants/buyout/statuses';
import {statusCodesResolver as reimbursementStatusCodesResolver} from '@/constants/reimbursement/statuses';
import {statusCodesResolver as homeServiceStatusCodesResolver} from '@/constants/home-service/statuses';

export default {
  methods: {
    getJobStatusText(claim) {
      let statusText;

      if (claim) {
        const {
          serviceJobType,
          serviceJobSubStatusName,
          serviceJobStatusName,
          serviceJobStatus,
          serviceJobSubstatus,
        } = claim;

        const virtualStatusesResolvers = {
          CLAIM_CARRYIN: carryInStatusCodesResolver,
          CLAIM_DROPOFF: depotStatusCodesResolver,
          CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE: replacementStatusCodesResolver,
          CLAIM_ONSITE: homeServiceStatusCodesResolver,
          CLAIM_SELF_REPAIR: reimbursementStatusCodesResolver,
          CLAIM_BUYOUT_WITHOUT_DEFECTIVE: buyoutStatusCodesResolver,
          ['Buyout - Accept']: buyoutStatusCodesResolver,
        };

        const resolver = virtualStatusesResolvers[serviceJobType];

        if (resolver) {
          let code = serviceJobSubstatus || serviceJobStatus;
          if (
            // HACK to recognize manually cancelled replacement on the Options provided stage
            claim?.serviceJobStatus === 'CAN' &&
            claim?.serviceJobStatusName === 'Cancelled' &&
            claim?.serviceJobType === 'CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE'
          ) {
            code = 'Replacement claim cancelled by customer';
          }
          statusText = resolver(claim, code)?.virtualStatus;
        }

        if (!statusText && serviceJobStatus == 'CAN') {
          if (claim?.serviceJobType === 'CLAIM_CARRYIN') {
            statusText = 'Repair cancelled';
          }
          if (claim?.serviceJobType === 'CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE') {
            statusText = 'Repair cancelled';
          }
          if (claim?.serviceJobType == 'CLAIM_DROPOFF') {
            statusText = 'Repair cancelled';
          }
        }

        if (!statusText) {
          statusText = serviceJobSubStatusName || serviceJobStatusName || serviceJobStatus;
        }
      }

      return statusText;
    },
  },
};
