import {getSharedStatuses, getStatusCodesResolver} from '@/utils/jobStatuses';
import toHtmlForProps from '@/utils/toHtmlForProps';

function getLocationNameWithDateAndTime(
  {serviceProviderName, scheduledServiceDate, claimDetails},
  {formattedDate, formattedHours},
  skipDate = true
) {
  let locationNameWithDateAndTime = 'chosen location';

  const {scheduledFromTime, scheduledToTime} = claimDetails;
  if (serviceProviderName && scheduledServiceDate && scheduledFromTime && scheduledToTime) {
    const from = formattedHours(...scheduledFromTime.split(':')).toLowerCase();
    const to = formattedHours(...scheduledToTime.split(':')).toLowerCase();

    const serviceNameAndDateChunks = [serviceProviderName];
    if (!skipDate) {
      const date = formattedDate(scheduledServiceDate?.split('T')?.[0]);
      serviceNameAndDateChunks.push(date);
    }

    locationNameWithDateAndTime = `<b>${serviceNameAndDateChunks.join(' ')} ${
      !skipDate ? '<br>' : ''
    } ${from} to ${to}</b>`;
  }

  return locationNameWithDateAndTime;
}

const sharedStatuses = getSharedStatuses({
  deviceNotEligibleForRepair: {
    text: (jobInfo, getters) => `
      Please check your Service Agreement for more information.
      If you have any questions, please call HomeServe at ${getters.phones.contactCenter}.
      Please remember to pick up your device at ${getLocationNameWithDateAndTime(jobInfo, getters)}.
    `,
  },
  repairCompleted: {
    text: (jobInfo, getters) =>
      `Your device is ready to be picked up at ${getLocationNameWithDateAndTime(jobInfo, getters)}`,
  },
  repairCancelled: {
    code: ['CAN', 'Cancelled'],
    virtualStatus: 'Repair cancelled',
    heading: 'Repair cancelled',
    text: (jobInfo, {phones}) => `
      The Service Call fee will be refunded to you. If you have any questions, please call HomeServe at ${phones.contactCenter}.
    `,
  },
  serviceCompleted: {
    text: 'Your Carry-In Service has successfully been completed.',
  },
});

const carryInStatuses = {
  serviceAccepted: {
    code: ['NEW', 'SC0061'],
    virtualStatus: 'Service accepted',
    // TODO: clarify whether claim data will contain location information
    text: (jobInfo, getters) =>
      `Please bring your device to ${getLocationNameWithDateAndTime(jobInfo, getters, false)}`,
    text2:
      '<b>Note: Before dropping off your device, please back it up and unlock it (or provide us with your access code/password).</b>',
  },
  carryinServiceCancelled: {
    code: ['CAN'],
    virtualStatus: 'Repair cancelled',
    heading: 'Repair cancelled',
    text: (_, {phones}) => `
    The Service Call fee will be refunded to you. If you have any questions, please call HomeServe at ${phones.contactCenter}.
    `,
  },
  // TODO: To clarify this status
  deviceRecieved: {
    code: 'SC0005',
    text: 'We’ve received your device and our technician is working on your job.',
  },
};

export const statuses = {
  ...sharedStatuses,
  ...carryInStatuses,
};

toHtmlForProps(statuses, 'text', 'text2');

export const statusCodesResolver = getStatusCodesResolver(statuses);

export default statuses;
