import toHtmlForProps from '@/utils/toHtmlForProps';
import {getStatusCodesResolver} from '@/utils/jobStatuses';

function getLocationNameWithDateAndTime(
  {serviceProviderName, scheduledServiceDate, claimDetails},
  {formattedDate, formattedHours},
  skipDate = true
) {
  let locationNameWithDateAndTime = 'chosen location';

  const {scheduledFromTime, scheduledToTime} = claimDetails;
  if (serviceProviderName && scheduledServiceDate && scheduledFromTime && scheduledToTime) {
    const from = formattedHours(...scheduledFromTime.split(':')).toLowerCase();
    const to = formattedHours(...scheduledToTime.split(':')).toLowerCase();

    const serviceNameAndDateChunks = [serviceProviderName];
    if (!skipDate) {
      const date = formattedDate(scheduledServiceDate?.split('T')?.[0]);
      serviceNameAndDateChunks.push(date);
    }

    locationNameWithDateAndTime = `<b>${serviceNameAndDateChunks.join(' ')} ${
      !skipDate ? '<br>' : ''
    } ${from} to ${to}</b>`;
  }

  return locationNameWithDateAndTime;
}

const YOU_WILL_RECEIVE_THIS_AMOUNT_TEXT =
  'You will receive this amount up to the benefit amount (minus the Service Call fee, if applicable.)';

const getFairMarketValueText = (claim) => {
  const buyoutAmountText = claim.claimDetails?.buyoutAmount;
  const amount = buyoutAmountText ? Number(buyoutAmountText).toFixed(0) : null;

  if (amount === null) {
    return '';
  }

  return `The fair market value of your device is $${amount}`;
};

const initialText = (claim) => {
  const fairMarketValueText = getFairMarketValueText(claim);

  const amountText = fairMarketValueText
    ? `${fairMarketValueText}. ${YOU_WILL_RECEIVE_THIS_AMOUNT_TEXT}`
    : '';

  return `
    ${amountText}
    Payment processing and delivery will take up to 30 days.
  `;
};

const amountAvailableText = (claim) => {
  const buyoutAmountText = claim.claimDetails?.buyoutAmount;
  const amount = buyoutAmountText ? Number(buyoutAmountText).toFixed(0) : 'a buyout amount';

  return `
    We will pay you $${amount} based on the fair market value of your device at the time the Service Call was made, up to the benefit limit amount minus the Service Call fee. Once you accept this buyout option, payment processing and delivery will take up to 30 days.
    <b>Note: Your device will be automatically removed from your device list. 
    Go to Service History to check on your Service Call progress.</b>
  `;
};

export const statuses = {
  selected: {
    code: ['Buyout Accepted', 'INITIATED', 'NEW', 'Buyout selected'],
    virtualStatus: 'Buyout Accepted',
    heading: 'Buyout Accepted',
    text: (claim) => {
      const buyoutAmountText = claim.claimDetails?.buyoutAmount;
      const amount = buyoutAmountText ? Number(buyoutAmountText).toFixed(0) : 'a buyout amount';
      return `
        We will pay you $${amount}. Payment processing and delivery will take up to 30 days.<br>
        <b>Note: HomeServe will be contacting you with the amount to be paid out to you. If you don't hear from us, feel free to call HomeServe at 1-833-785-4414. In addition, your device has been automatically removed from your device list.</b.
      `;
    },
  },

  replacementDeclining: {
    text: (claim) => {
      const buyoutAmountText = claim.claimDetails?.buyoutAmount;
      const amount = buyoutAmountText ? Number(buyoutAmountText).toFixed(0) : 'a buyout amount';
      return `
        We will pay you $${amount} based on the fair market value of your device at the time the Service Call was made, up to the benefit limit amount minus the Service Call fee. Once you accept this buyout option, payment processing and delivery will take up to 30 days.
        <b>Note: Your device will be automatically removed from your device list. 
        Go to Service History to check on your Service Call progress.</b>
      `;
    },
  },

  amountInReview: {
    code: 'Buyout Amount Pending',
    virtualStatus: 'Buyout amount in review',
    text: 'Time is needed for us to determine the fair market value of your device. Once available, we will notify you via emails/SMS for you to review and accept the Buyout option.',
  },

  amountAvailable: {
    code: 'SC0100',
    virtualStatus: 'Ready for Decision',
    heading: 'Please review the Buyout amount available',
    text: amountAvailableText,
  },

  // TODO: double check all status codes below
  inProgress: {
    code: ['Buyout in progress', 'ACCEPTED'],
    virtualStatus: 'Buyout in progress',
    text: initialText,
  },
  sbAccepted: {
    code: ['ACC'],
    virtualStatus: 'Buyout Accepted',
    heading: 'Buyout Accepted',
    text: (claim) => {
      const buyoutAmountText = claim.claimDetails?.buyoutAmount;
      const amount = buyoutAmountText ? Number(buyoutAmountText).toFixed(0) : 'a buyout amount';
      return `
        We will pay you $${amount}. Payment processing and delivery will take up to 30 days.<br>
        <b>Note: HomeServe will be contacting you with the amount to be paid out to you. If you don't hear from us, feel free to call HomeServe at 1-833-785-4414. In addition, your device has been automatically removed from your device list.</b>
      `;
    },
  },
  sbInProcess: {
    code: ['DIP'],
    virtualStatus: 'In process',
    heading: 'In process',
    text: (claim) => {
      const buyoutAmountText = claim.claimDetails?.buyoutAmount;
      const amount = buyoutAmountText ? Number(buyoutAmountText).toFixed(0) : 'a buyout amount';
      return `
        We will pay you $${amount}. Payment processing and delivery will take up to 30 days.
      `;
    },
  },
  sbAwaitingPayment: {
    code: ['AWT'],
    virtualStatus: 'Job Completed - Awaiting payment',
    heading: 'Job Completed - Awaiting payment',
    text: `
      We have processed your buyout request.<br>
      <b>Note: the failed device (that you are receiving payment for) is no longer eligible for any coverage. It has been automatically removed from your device list. Once you purchase a new device with the buyout payment, we recommend that you register it for coverage with your current plan by adding it to your device list.</b>
    `,
  },

  processed: {
    code: ['COMPLETED', 'Buyout processed'],
    virtualStatus: 'Buyout processed',
    text: (_, {phones}) => `
      We have processed your buyout request.
      HomeServe will be contacting you with the amount to be paid out to you.
      If you don't hear from us, feel free to call HomeServe at ${phones.contactCenter}.

      <br>
      <b>
        Note: the failed device (that you are receiving payment for) is no longer eligible for any coverage.
        It has been automatically removed from your device list.
        Once you purchase a new device with the buyout payment, we recommend that you register it for coverage with your current plan by adding it to your device list.
      </b>
    `,
  },

  completed: {
    code: ['CLOSED', 'Buyout completed', 'CMP'],
    virtualStatus: 'Buyout completed',
    text: 'Your Buyout has been successfully completed.',
  },

  // TODO: this status is the most confusing one
  // according to the statuses description in the designs
  // there are multiple cases with the same exact ESB status
  // requires eventual clarification and thorough testing
  cancelled: {
    code: ['Buyout Rejected', 'CANCELLED', 'Buyout cancelled'],
    virtualStatus: 'Buyout Rejected',
    heading: 'Buyout declined, your Service Call is cancelled',
    text: (_, {phones}) => `
      If you have any questions, please call HomeServe at ${phones.contactCenter}.
    `,
  },
  depotRejected: {
    code: ['Depot Rejected'],
    virtualStatus: 'Buyout Rejected',
    heading: 'Buyout declined, your Service Call is cancelled',
    text: (_, {phones}) => `
      Your device will be sent back to you. If you have any questions, please call HomeServe at ${phones.contactCenter}.
    `,
  },
  carryInRejected: {
    code: ['Carry In Rejected'],
    virtualStatus: 'Buyout Rejected',
    heading: 'Buyout declined, your Service Call is cancelled',
    text: (jobInfo, getters) => `
      Please remember to pick up your device from ${getLocationNameWithDateAndTime(
        jobInfo,
        getters,
        true
      )}. If you have any questions, please call HomeServe at ${getters.phones.contactCenter}.
    `,
  },
};

toHtmlForProps(statuses, 'text');

export const statusCodesResolver = getStatusCodesResolver(statuses);

export default statuses;
