import toHtmlForProps from '@/utils/toHtmlForProps';
import {getSharedStatuses, getStatusCodesResolver} from '@/utils/jobStatuses';

// TODO: test status/statusBackToCustomer statuses resolver with actual claims data
// TODO: Check values for shippingFromCustomerStatus
//       and potentially present it to the user
// TODO: adjust status texts to also include different duration for different
//       device type, for example:
//       https://www.figma.com/file/iDdZ0V1xGKzRdcYvx8kxlH/WB_DG_0037-Consumer-Electronics?node-id=7612%3A73328

const sharedStatuses = getSharedStatuses({
  serviceCompleted: {
    text: 'Your Depot Service has successfully been completed.',
  },
  // TODO: make sure that when service is cancelled it is always
  //       due to not receiving device for 14 days
  //       If so - uncomment the override below
  // serviceCancelled: {
  //   heading: 'Your Service Call has been cancelled. It’s been 14 days since you set up Depot Service and we haven’t received your device.',
  //   text: (_, {phones}) => `If you have any questions, please call HomeServe at ${phones.contactCenter}.`,
  // },
  repairCompleted: {
    text: (jobInfo, {shippingInformation}) => `
      Your device has been repaired and is ready for the shipping carrier.
      ${shippingInformation(jobInfo)}
    `,
  },
  repairCancelled: {
    code: ['CAN', 'Cancelled'],
    virtualStatus: 'Repair cancelled',
    heading: 'Repair cancelled',
    text: (jobInfo, {phones}) => `
      The Service Call fee will be refunded to you. If you have any questions, please call HomeServe at ${phones.contactCenter}.
    `,
  },
  deviceNotEligibleForRepair: {
    text: (jobInfo, {shippingInformation, phones}) => `
      Please check your Service Agreement for more information.
      If you have any questions, please call HomeServe at ${phones.contactCenter}.
      Your device will be sent back to you.
    `,
  },
});

export const depotStatuses = {
  requestAccepted: {
    code: ['NEW', 'SC0078'],
    virtualStatus: 'Drop off request accepted', // workaround for not working update of serviceJobSubStatusName
    text: ({deviceName, dropOffLocation = {}}) => {
      const device = !!deviceName ? ` ${deviceName}` : '';
      const locationName = dropOffLocation.name.endsWith('.')
        ? dropOffLocation.name.slice(0, -1)
        : dropOffLocation.name;

      const locationInfo = `<b>${locationName}, ${dropOffLocation.fullAddress}</b>`;

      return `
        Please drop off your device ${device} packed in box with shipping label at: ${locationInfo}.`;
    },
    text2: (jobInfo) => {
      const DAYS = {
        Tablet: 2,
        Laptop: 5,
        ['CPU Tower']: 5,
        ['Desktop Computer']: 5,
        ['Monitor']: 5,
      };
      const days = DAYS[jobInfo?.productLineName];
      const suffix = days ? ` You will receive a packing box within ${days} business days.` : '';
      return `<b>Here is your shipping label.${suffix}</b> Please print and then affix label to the box.`;
    },
    text3:
      '<b>Note: Before packing your device, please back it up and unlock it (or provide us with your access code/password).</b>',
  },

  // TODO: to clarify
  deviceDispatched: {
    code: 'SC0081',
    virtualStatus: 'Device dispatched',
    heading: 'Device dispatched',
    text: (jobInfo) => {
      const {shippingToCustomerTrackingID, shippingToCustomerVendor} = jobInfo.claimDetails;
      return `
        Your device is on its way via <b>shipping carrier ${shippingToCustomerVendor},
        tracking number ${shippingToCustomerTrackingID}</b>.
      `;
    },
  },
  // TODO: to clarify
  deviceWithLogisticsPartner: {
    code: 'SC0068',
    virtualStatus: 'Service Call in progress',
    heading: 'Your Service Call is in progress',
    text: 'We are in the process of resolving your request and your Service Call is in progress.',
  },

  deviceInTransit: {
    code: 'SC0069',
    virtualStatus: 'In-Transit',
    heading: 'In-Transit',
    text: 'Your device is on its way.',
  },
  deviceInTransitBackToCustomer: {
    code: 'SC0069',
    text: 'Your device is on its way.',
  },

  deviceIsOutForDelivery: {
    code: 'SC0071',
    virtualStatus: 'Out for delivery',
    heading: 'Out for delivery',
    text: 'Your device is on its way.',
  },
  deviceIsOutForDeliveryBackToCustomer: {
    code: 'SC0071',
    virtualStatus: 'Out for delivery',
    heading: 'Out for delivery',
    text: 'Your device is on its way.',
  },

  deviceDelivered: {
    code: 'SC0070',
    heading: 'Device Delivered',
    text: 'We’ve delivered your device to our service center and our technician is working on your job.',
  },
  deviceDeliveredBackToCustomer: {
    code: 'SC0070',
    heading: 'Device Delivered',
    text: 'Your repaired device has been successfully delivered to your confirmed address.',
  },

  deliveryAttemptFailed: {
    code: 'SC0072',
    heading: 'Delivery attempt failed',
    text: (jobInfo, {shippingInformation, phones}) => `
      We tried to deliver your device, but the delivery was unsuccessful.
      We will try again.
      Please reach out to the shipping carrier for details or call HomeServe at ${phones.contactCenter}.
    `,
  },
  deviceDeliveryBackToCustomerAttemptFailed: {
    code: 'SC0072',
    text: (_, {phones}) => `
      We tried to deliver your device, but the delivery was unsuccessful.
      We will try again.
      Please reach out to the shipping carrier for details or call HomeServe at ${phones.contactCenter}.
    `,
  },

  returnToShipper: {
    code: 'SC0075',
    heading: 'Return to shipper (you)',
    text: (_, {phones}) => `
      We tried 3 times to deliver your device, but all attempts were unsuccessful. Your device is on its way back. If you have any questions, please call HomeServe at ${phones.contactCenter}.
    `,
  },
  returnToShipperBackToCustomer: {
    code: 'SC0075',
    text: (jobInfo, {shippingInformation, phones}) => `
      We tried 3 times to deliver your device, but all attempts were unsuccessful. Your device is on its way back. If you have any questions, please call HomeServe at ${phones.contactCenter}.
    `,
  },

  // TODO: to clarify
  deviceReceived: {
    code: 'SC0005',
    text: 'We’ve received your device and our technician is working on your job.',
  },
  // TODO: to clarify
  buyoutDeclined: {
    heading: 'Your Service Call is cancelled',
    text: (jobInfo, {shippingInformation, phones}) => `
      Your device is with the shipping carrier and will be sent back to you.
      ${shippingInformation(jobInfo)}
      If you have any questions, please call HomeServe at ${phones.contactCenter}.
    `,
  },
};

export const statuses = {
  ...sharedStatuses,
  ...depotStatuses,
};

toHtmlForProps(statuses, 'text', 'text2', 'text3');

// TODO: test if this approach is functioning and its reliability
const resolverCallback = (jobInfo, statuses) => {
  let status = statuses[0];

  const {shippingToCustomerTrackingID} = jobInfo.claimDetails;
  const findCallback = (status) => status.name.includes('BackToCustomer');

  if (shippingToCustomerTrackingID) {
    status = statuses.find(findCallback);
  } else {
    status = statuses.find((status) => !findCallback(status));
  }

  return status;
};

export const statusCodesResolver = getStatusCodesResolver(statuses, resolverCallback);

export default statuses;
