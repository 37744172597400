<template>
  <div class="device-icon" :class="classes"></div>
</template>

<script>
const classPrefix = 'device-icon';
export default {
  props: {
    icon: String,
    grayBorder: Boolean,
    mini: Boolean,
  },
  computed: {
    classes() {
      const {icon, mini, grayBorder} = this;
      const iconClass = `${classPrefix}--${icon.toLowerCase()}`;
      return {
        [iconClass]: true,
        [`${classPrefix}--mini`]: mini,
        [`${iconClass}-mini`]: mini,
        [`${classPrefix}--gray-border`]: grayBorder,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.device-icon {
  position: relative;
  display: block;
  flex-shrink: 0;
  background-image: url('~@/assets/images/devices/pc.svg');
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 58% auto;
  border-radius: 50%;

  &--mini {
    background-color: $lighterGray;
    background-size: 70% auto;

    &::after,
    &::before {
      display: none;
    }
  }

  &--smart_phone,
  &--smartphone,
  &--phone {
    background-image: url('~@/assets/images/devices/phone.svg');
    background-size: 28% auto;
    &-mini {
      background-size: 40% auto;
    }
  }
  &--tablet {
    background-image: url('~@/assets/images/devices/tablet.svg');
    background-size: 39% auto;
    &-mini {
      background-size: 50% auto;
    }
  }
  &--television {
    background-image: url('~@/assets/images/devices/tv.svg');
    background-size: 53% auto;
    &-mini {
      background-size: 60% auto;
    }
  }
  &::after,
  &::before {
    position: absolute;
    border: 1px solid #88d77b;
    border-radius: 50%;
    content: '';
  }
  &::after {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  &::before {
    top: 5px;
    right: 5px;
    bottom: 5px;
    left: 5px;
  }
  &--gray-border {
    &::after,
    &::before {
      border-color: $mediumGray;
    }
  }
}
</style>
